// export default [
//   {
//     header: 'Pages',
//     icon: 'FileIcon',
//     children: [
//       {
//         title: 'Home',
//         route: 'home',
//         icon: 'HomeIcon',
//       },
//       {
//         title: 'Second Page',
//         route: 'second-page',
//         icon: 'FileIcon',
//       },
//     ],
//   },
// ]

export default [
  // {
  //   title: 'Pano',
  //   route: 'statistics',
  //   icon: 'GridIcon',
  // },
  // {
  //   title: 'CRM',
  //   route: 'crm',
  //   icon: 'TrelloIcon',
  // },
  // {
  //   title: 'Yazılı Kanal',
  //   route: 'apps-chat',
  //   icon: 'MessageCircleIcon',
  // },
  // {
  //   title: 'Ajanda',
  //   route: 'apps-calendar',
  //   icon: 'CalendarIcon',
  // },
  // {
  //   title: 'Rehber',
  //   route: 'phone-book',
  //   icon: 'BookIcon',
  // },
  // {
  //   header: globalThis._lang("t_admin")
  // },
  {
    title: globalThis._lang("t_agents"),
    route: 'spv_dashboard',
    icon: 'UsersIcon',
  },
  {
    title: 'Wallboard',
    icon: 'PieChartIcon',
    route: 'wallboard'
  },
  {
    title: globalThis._lang("t_displayCalls"),
    icon: 'PhoneIcon',
    route: 'dashboard'
  },
  {
    title: globalThis._lang("t_appointments"),
    icon: 'ListIcon',
    route: 'appointments'
  },
  {
    title: globalThis._lang("t_callAnalyze"),
    icon: 'ActivityIcon',
    route: 'call_analize',
    img: 'callexper_slogo.png',
    // disabled: true,

  },

  {
    title: globalThis._lang("t_callRecords"),
    icon: 'CheckSquareIcon',
    route: 'quality',
  },
  {
    title: globalThis._lang("t_reports"),
    icon: 'SearchIcon',
    route: 'reports',
  },
  {
    title: globalThis._lang("t_dialer"),
    icon: 'SlidersIcon',
    route: 'dialer'
  },


  {
    header: globalThis._lang("t_tools2"),
    icon: 'FileIcon',
    children: [
      {
        title: globalThis._lang("t_goalManagement"),
        route: 'goal_management',
        icon: 'TargetIcon',
      },

      {
        title: globalThis._lang("t_mAlerts"),
        icon: 'AlertCircleIcon',
        route: 'alerts',
      },

      {
        title: globalThis._lang("t_sms"),
        icon: 'MessageSquareIcon',
        route: 'sms'
      },

      {
        title: globalThis._lang("t_eMail"),
        icon: 'MailIcon',
        route: 'email'
      },
      {
        title: globalThis._lang("STT"),
        icon: 'EditIcon',
        route: 'stt_edit'
      },
    ]
  },
  {
    header: globalThis._lang("t_configuration2"),
    icon: 'FileIcon',
    children: [
      {
        title: globalThis._lang("t_systemManagement"),
        icon: 'ToolIcon',
        route: 'system_management'
      },
      {
        title: globalThis._lang("t_operationManagement"),
        icon: 'UserIcon',
        route: 'operation_management',

      },

      {
        title: globalThis._lang("t_settings"),
        icon: 'SettingsIcon',
        route: 'settings'

      },
    ],
  },















]

