<template>
  <b-container>
    <b-row>
      <b-col cols="12">
        <h4 v-text="verdict"></h4>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <span
          style="
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 200px;
          "
        >
          {{ `${customer_no} - ${customer_name}` }}
        </span>
        <p
          style="
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 200px;
          "
        >
          {{ note }}
        </p>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-button @click="clicked" :variant="color" block>
          <feather-icon class="float-left" icon="PhoneIcon" size="14" />
          ARA :
          {{
            this.other_permission.includes("mask_remote_number")
              ? maskPhoneNumber(remoteNumber)
              : remoteNumber
          }}
        </b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
  BContainer, BCol, BRow, BButton
} from 'bootstrap-vue'
export default {
  data() {
    return {
      color: 'success',
      other_permission: []

    }
  },
  components: {
    BContainer, BCol, BRow, BButton
  },
  props: {
    variant: {
      type: String,
      default: 'primary',
    },
    icon: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
    hideClose: {
      type: Boolean,
      default: false,
    },
    verdict: {
      type: String,
      default: null,
    },
    remoteNumber: {
      type: String,
      default: null,
    },
    note: {
      type: String,
      default: null,
    },
    customer_name: {
      type: String,
      default: null,
    },
    customer_no: {
      type: String,
      default: null,
    },
  },
  methods: {
    clicked() {
      this.$emit("myClick");
      this.color = 'warning';
    },
    maskPhoneNumber(phoneNumber) {
      var maskedNumber = phoneNumber.slice(0, -2).replace(/./g, '*') + phoneNumber.slice(-2);
      return maskedNumber;
    }

  },
  mounted: async function () {
    this.other_permission = globalThis.permissions["other"] || [];
  }
};
</script>
